<template>
  <a-card :border="false">
    <SmallSquare content="针对平台付费模板进行管理，如果需要为某品牌商开通指定模板类型的使用权限，将该品牌商添加进入开通表即可，如需关闭使用权限，则进行移除操作。" />
    <a-button class="m-b-20 m-t-20" type="primary" @click="openAdd">
      <PlusOutlined />新增数据
    </a-button>
    <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search" :onFinish="pageSearchChange" layout="inline">
      <a-form-item label="品牌商名称" name="keywords">
        <a-input v-model:value="search.keywords" style="width: 250px" placeholder="请输入品牌商名称进行搜索"></a-input>
      </a-form-item>
      <a-form-item label="开通时间" name="">
        <a-range-picker valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" @change="onChange" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType="submit">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table class="m-t-20" bordered :data-source="dataSource" :columns="columns" :pagination="false" rowKey="id"
      :loading="listLoading">
      <template v-slot:action="{ record }">
        <a-button type="link" v-has:payTemDel="()=>brandDel(record)">删除</a-button>
      </template>
    </a-table>
    <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper
      :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
      @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-card>
  <a-modal v-model:visible="openVisible" title="开通付费模板" destroyOnClose centered :confirm-loading="confirmLoading" @ok="onConfirm">
    <div class="s_flex_ali m-b-25">
      <p class="s_required width-90 s_text_r">品牌商：</p>
      <a-input class="width65" v-model:value="formData.brand_name" readonly placeholder="请选择品牌商"></a-input>
      <a-button class="m-l-10" type="primary" @click="brandVisible=true">选择</a-button>
    </div>
    <div class="s_flex_ali">
      <p class="s_required width-90 s_text_r">模板类型：</p>
      <a-select class="width65" v-model:value="formData.type" placeholder="请选择" @change="typeChange">
        <a-select-option :value="item.type" v-for='item in typeLits' :key='item'>{{item.name}}</a-select-option>
      </a-select>
    </div>
  </a-modal>
  <!-- 品牌商弹窗 -->
  <a-modal v-model:visible="brandVisible" title="选择品牌商" :width='1200' destroyOnClose centered :footer="null">
    <selectBrand v-on:select="brandSelect" :checkout="false" :selectList="[{id:formData.brand_id}]" />
  </a-modal>
</template>

<script>
  import { PlusOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject } from "vue";
  import { getCodeTemplateList,getCodeTemplateSysList,setCodeTemplate,codeTemplateDel } from "@/api/product";
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  let defSearch = {
    keywords: "",
    page: 1,
    limit: 10,
    start_time: "",
    end_time: "",
  };
  let defForm = {
    brand_id: "",
    brand_name:"",
    type:null,
    template_uuid:""
  };
  export default {
    name: 'agentList',
    components: {PlusOutlined},
    setup(props) {
      let search = ref({ ...defSearch });
      let formData = ref({ ...defForm });
      const state = reactive({
        openVisible: false,
        brandVisible:false,
        confirmLoading:false,
        dataSource: [],
        listLoading: false,
        typeLits:[],
        total:0,
        columns: [
          {
            title: "品牌商",
            dataIndex: "brand_name",
          },
          {
            title: "模板类型",
            dataIndex: "type_text",
          },
          {
            title: "账号",
            dataIndex: "account",
          },
          {
            title: "开通时间",
            dataIndex: "created_time",
          },
          {
            title: "操作",
            key: "action",
            width:120,
            slots: { customRender: "action" },
          },
        ],
      });
    const $Modal = inject('$Modal');
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData();
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData();
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData();
      };
      //选择品牌商
      const brandSelect = (e) => {
        formData.value.brand_id = e.brand_no;
        formData.value.brand_name = e.brand_name;
        state.brandVisible = false;
      };
      //删除
      const brandDel = (e) => {
        $Modal.confirm({
          title: '提示',
          content: '删除后该品牌商将无法使用此付费模板，确定删除？',
          centered:true,
          onOk: async () => {
            let res = await codeTemplateDel({brand_id:e.brand_id,id:e.id}).then((res) => res.data).catch(error => error)
            state.listLoading = false;
            if ($iscode(res)) {
              initData();
            } else {
              message.error(res.msg);
            }
          },
        });
      };
      //打开添加
      const openAdd = () =>{
        state.openVisible=true;
        formData.value = {
          brand_id: "",
          brand_name:"",
          type:null,
          template_uuid:""
        }
      }
      //确定添加
      const onConfirm = async() =>{
        if(!formData.value.brand_id){
          message.error('请选择品牌商');
          return
        }
        if(!formData.value.template_uuid){
          message.error('请选择模板类型');
          return
        }
        state.confirmLoading = true;
        let res = await setCodeTemplate({...formData.value}).then((res) => res.data).catch(error => error)
        state.confirmLoading = false;
        if ($iscode(res)) {
          message.success(res.msg);
          state.openVisible = false;
          initData();
        } else {
          message.error(res.msg);
        }
      }
      //获取类型
      const getType = async() =>{
        let res = await getCodeTemplateSysList().then(res => res.data).catch(error => error)
        if ($iscode(res)) {
          state.typeLits = res.data;
        } else {
          message.error(res.msg);
        }
      }
      //选择类型
      const typeChange = (e,options) =>{
        formData.value.type = options.key.type;
        formData.value.template_uuid = options.key.template_uuid
        console.log(e,options)
      }
      //列表
      const initData = async () => {
        state.listLoading = true;
        state.dataSource = [];
        let res = await getCodeTemplateList({...search.value}).then((res) => res.data).catch(error => error)
        state.listLoading = false;
        if ($iscode(res)) {
          state.dataSource = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.msg);
        }
      };
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.start_time = e[0];
          search.value.end_time = e[1];
        } else {
          search.value.start_time = "";
          search.value.end_time = "";
        }
      };
      onMounted(() => {
        initData();
        getType();
      });
      return {
        ...toRefs(state),
        search,
        formData,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
        brandSelect,
        brandDel,
        onConfirm,
        typeChange,
        openAdd
      };
    },
  };
</script>